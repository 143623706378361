/* eslint-disable @next/next/no-img-element */
import type { TrackingMeta } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { CookiesMapped, Deviceoutput } from '@/core/features/cookies/cookies-service';

import React from 'react';

import { IS_SERVER, NEXT_PUBLIC_GENERAL_TRACKING_PRODUCT_NAME } from '@/constants/env';
import { useCookiesMapped } from '@/core/features/app/app-atoms';
import { getDeviceInfo, getDeviceOSMapped } from '@/core/features/cookies/cookies-service';
import { logger } from '@/core/features/logger/logger';
import useClientOnlyRender from '@/core/features/render/use-client-only-render';
import FeatureFlagStore from '@/core/features/store/feature-flags-store';

const featureFlagStore = FeatureFlagStore();

type GeneralTrackingParams = {
    additionalSearchParams?: Record<string, string>;
    cookiesMapped: CookiesMapped;
    deviceoutput: Deviceoutput;
    overrides?: {
        actionId?: string;
        areaId?: string;
    };
    trackingMeta: TrackingMeta;
};

const getGeneralTrackingUrl = ({
    additionalSearchParams,
    cookiesMapped,
    deviceoutput,
    overrides,
    trackingMeta,
}: GeneralTrackingParams): null | string => {
    const { indiSession } = cookiesMapped;
    if (!NEXT_PUBLIC_GENERAL_TRACKING_PRODUCT_NAME) {
        logger.error('NEXT_PUBLIC_GENERAL_TRACKING_PRODUCT_NAME is missing in env Variables');
        return null;
    }

    if (!indiSession) {
        logger.error('indiSession is missing in getGeneralTrackingUrl');
        return null;
    }

    const areaId = overrides?.areaId ?? trackingMeta.areaId;
    const actionId = overrides?.actionId ?? trackingMeta.actionId;

    // in case of request error we fallback the areaId with dynamicPageMetaFallback to empty string
    // in that case we should not track the page
    if (areaId === '') {
        return null;
    }

    const product = NEXT_PUBLIC_GENERAL_TRACKING_PRODUCT_NAME;

    const { pid, siteId } = trackingMeta;
    const { deviceOS } = getDeviceInfo(cookiesMapped.deviceinfo, indiSession);
    const deviceOsMapped = getDeviceOSMapped(deviceOS);

    const url = new URL(
        `https://asset42.check24.de/gif/site_id/${siteId}/pid/${pid}/area_id/${areaId}/product/${product}/sid/${indiSession}/action_id/${actionId}/deviceoutput/${deviceoutput}/os/${deviceOsMapped}/image.gif`,
    );

    if (additionalSearchParams) {
        Object.entries(additionalSearchParams).forEach(([key, value]) => {
            url.searchParams.set(key, value);
        });
    }

    return url.toString();
};

// https://c24-indi.atlassian.net/browse/INDI-3416
export const fireGeneralTracking = ({
    additionalSearchParams,
    cookiesMapped,
    deviceoutput,
    overrides,
    trackingMeta,
}: GeneralTrackingParams): void => {
    const url = getGeneralTrackingUrl({
        additionalSearchParams,
        cookiesMapped,
        deviceoutput,
        overrides,
        trackingMeta,
    });

    if (!url) {
        return;
    }

    if (!IS_SERVER) {
        featureFlagStore.log('trackingLogs', `Tracking Log: GT Event, URL: ${url}`);
    }

    fetch(url, { mode: 'no-cors' }).catch((_error) => {
        // console.info(`Error while firing general tracking, error: ${_error}`);
    });
};

/**
 * https://jira.check24.de/browse/INDI-693
 * Example: https://asset42.check24.de/gif/site_id/168/pid/ch24_indi_hp_dti/area_id/s0/product/indi/sid/69584682545/action_id/1941/deviceoutput/mobile/image.gif
 */
export const C24GeneralTrackingBodyPixel = ({
    additionalSearchParams,
    deviceoutput,
    trackingMeta,
}: {
    additionalSearchParams?: Record<string, string>;
    deviceoutput: Deviceoutput;
    trackingMeta: TrackingMeta;
}) => {
    const prevTrackedUrl = React.useRef('');

    const shouldRender = useClientOnlyRender();
    const cookiesMapped = useCookiesMapped();

    // tracking pixel should not render server-side as it is sessionStorage dependent
    if (!shouldRender) {
        return null;
    }

    if (trackingMeta.manualGeneralTracking) {
        return null;
    }

    const url = getGeneralTrackingUrl({
        additionalSearchParams,
        cookiesMapped,
        deviceoutput,
        trackingMeta,
    });

    if (!url) {
        return null;
    }

    if (prevTrackedUrl.current !== url) {
        prevTrackedUrl.current = url;

        if (!IS_SERVER) {
            featureFlagStore.log('trackingLogs', `Tracking Log: GT Event, URL: ${url}`);
        }
    }

    return (
        <img
            alt={''}
            id={'asset42'}
            src={url}
        />
    );
};
