/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-this */

import type { CookiesMapped } from '@/core/features/cookies/cookies-service';

import { IS_SERVER } from '@/constants/env';
import { getBrowserCookie, setBrowserCookie } from '@/core/features/cookies/cookies-service';
import { logger } from '@/core/features/logger/logger';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Store<V> = Record<string, V>;
type StoreValue<T> = T[keyof T];
type StoreConfig = {
    isTopLevel: boolean;
};

const enableLogging = false;

/* eslint-disable fp/no-class */
export default class CookieStorage<T extends Store<StoreValue<T>>> {
    private readonly config: StoreConfig;
    private readonly storeName: keyof CookiesMapped;

    constructor(storeName: keyof CookiesMapped, config?: Partial<StoreConfig>) {
        this.storeName = storeName;
        this.config = {
            isTopLevel: false,
            ...config,
        };
    }

    private getIsomorphicCookieStore(cookiesMapped: CookiesMapped | undefined) {
        if (IS_SERVER) {
            if (!cookiesMapped) {
                logger.error('CookieStorage: cookiesMapped is required on server side');
                return null;
            }
            return cookiesMapped[this.storeName];
        }
        return getBrowserCookie(this.storeName);
    }

    private getStore(cookiesMapped?: CookiesMapped): T | null {
        const store = this.getIsomorphicCookieStore(cookiesMapped);

        if (!store) {
            return null;
        }
        logCookieStorageEvent(`getStore ${this.storeName}, store: ${JSON.stringify(store)}`);
        return this.parse(store);
    }

    private parse(store: string): T {
        try {
            return JSON.parse(store);
        } catch (error) {
            logger.error(`Storage parsing error: ${error}`);
            throw error;
        }
    }

    private setStore(store: T): void {
        if (IS_SERVER) {
            logger.error('setting cookie store is not supported on server side');
            return;
        }

        if (Object.keys(store).length === 0) {
            // cleanup
            setBrowserCookie(this.storeName, '', { isTopLevel: this.config.isTopLevel });
            return;
        }

        const stringifiedStore = this.stringify(store);
        logCookieStorageEvent(`setStore ${this.storeName}, store: ${stringifiedStore}`);
        setBrowserCookie(this.storeName, stringifiedStore, { expires: 'session', isTopLevel: this.config.isTopLevel });
    }

    private stringify(store: T): string {
        return JSON.stringify(store);
    }

    getKey(key: string, cookiesMapped: CookiesMapped | undefined): StoreValue<T> | null {
        const store = this.getStore(cookiesMapped);
        if (!store) {
            return null;
        }
        logCookieStorageEvent(`getKey, key: ${key}, value: ${store[key]}, store: ${JSON.stringify(store)}`);

        return store[key] ?? null;
    }

    removeKey(key: string): void {
        if (IS_SERVER) {
            logger.error(`CookieStorage: removeItem is not supported on server side, key: ${key}`);
            return;
        }
        const store = this.getStore();
        if (!store) {
            return;
        }
        logCookieStorageEvent(`removeKey, key: ${key}, store: ${JSON.stringify(store)}`);

        const { [key]: _removedKey, ...updatedStore } = store;
        this.setStore(updatedStore as T);
    }

    setKey(key: string, value: StoreValue<T>): void {
        if (IS_SERVER) {
            logger.error(`CookieStorage: setKey is not supported on server side, key: ${key}`);
            return;
        }
        const store = this.getStore();
        logCookieStorageEvent(`setKey, key: ${key}, store: ${JSON.stringify(store)}`);

        this.setStore({ ...store, [key]: value } as T);
    }
}

const logCookieStorageEvent = (...message: string[]) => {
    if (!enableLogging) {
        return;
    }
    // eslint-disable-next-line no-console
    console.info('CookieStorage:', ...message);
};
