import React from 'react';

import { useRouterEvents } from '@/core/features/router/router-events';
import { atom, useAtom, useAtomValue, useSetAtom } from '@/core/features/store/atom-store';

const isTravelFormExpandedAtom = atom(false);
const isTravelFormLoadingAtom = atom(false);
const isTravelFormSubmitLoadingAtom = atom(false);

const hasTravelFormChanged = atom(false);

const isTravelFormDataLoadingAtom = atom(false);
export const useTravelFormChanged = () => useAtom(hasTravelFormChanged);

export const useSetHasTravelFormChanged = () => useSetAtom(hasTravelFormChanged);
export const useIsTravelFormDataLoading = () => useAtomValue(isTravelFormDataLoadingAtom);
export const useSetIsTravelFormLoading = () => useSetAtom(isTravelFormDataLoadingAtom);

export default function useTravelFormCta() {
    const routerEvents = useRouterEvents();
    const [isTravelFormExpanded, setTravelFormExpanded] = useAtom(isTravelFormExpandedAtom);
    const [isTravelFormLoading, setTravelFormLoading] = useAtom(isTravelFormLoadingAtom);

    React.useEffect(() => {
        const handleRouteChange = () => setTravelFormExpanded(false);
        routerEvents.on('routeChangeComplete', handleRouteChange);

        return () => routerEvents.off('routeChangeComplete', handleRouteChange);
    }, [routerEvents, setTravelFormExpanded]);

    const onCollapsedFormClick = () => {
        setTravelFormLoading(true);
        setTravelFormExpanded(true);
    };

    const collapseTravelForm = () => {
        setTravelFormExpanded(false);
    };

    const onTravelFormLoaded = () => {
        if (isTravelFormLoading) {
            setTravelFormLoading(false);
        }
    };

    return {
        collapseTravelForm,
        isTravelFormExpanded,
        isTravelFormLoading,
        onCollapsedFormClick,
        onTravelFormLoaded,
    };
}

export const useTravelFormSubmitLoading = () => {
    const [isTravelFormSubmitLoading, setTravelFormSubmitLoading] = useAtom(isTravelFormSubmitLoadingAtom);
    const routerEvents = useRouterEvents();

    React.useEffect(() => {
        const handleRouteChange = () => setTravelFormSubmitLoading(false);
        routerEvents.on('routeChangeComplete', handleRouteChange);

        return () => routerEvents.off('routeChangeComplete', handleRouteChange);
    }, [routerEvents, setTravelFormSubmitLoading]);

    return { isTravelFormSubmitLoading, setTravelFormSubmitLoading };
};
