import type { ILayoutId } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { MapTrackingElement } from '@/features/map/map-container/map-tracking';
import type { BoundariesType } from '@/features/map/map-data-v5/map-data-v5-service';

import React from 'react';

import { clientUrlAtom, layoutIdAtom, useClientUrl } from '@/core/features/app/app-atoms';
import { useOverlayHistoryState } from '@/core/features/modals/overlay-history/overlay-history-state';
import { atom, useAtomValue, useSetAtom } from '@/core/features/store/atom-store';
import { getUrlPath } from '@/core/utils/url';
import { googleMapInstanceAtom } from '@/features/map/google-map/google-map-state';
import { logMap } from '@/features/map/map-container/map-logger';
import {
    isMapSearchLayout,
    mapActivePoiKeyStateAtom,
    overlayHistoryCurrentMapStateAtom,
} from '@/features/map/map-container/map-state';
import { trackMapOpenClick } from '@/features/map/map-container/map-tracking';
import { getMapLatLngLiteral } from '@/features/map/map-data-v5/map-data-v5-service';
import { mapBoundariesForComponentsAtom, useSetMapDataV5EntryTarget } from '@/features/map/map-data-v5/use-map-data-v5';

type MapDataHistoryState = {
    mapData: {
        activePoiKey: null | string;
        bottomSheetScrollPosition?: number;
        isBottomSheetOpen?: boolean;
        location?: { latitude: number; longitude: number };
        mapBoundariesForComponents: BoundariesType | null;
        url: string;
        zoom?: number;
    };
};

const updateMapOverlayHistoryStateRecordAtom = atom(
    null,
    (
        get,
        set,
        {
            bottomSheetScrollPosition,
            debugNamespace,
            isBottomSheetOpen,
            poiKey,
        }: {
            bottomSheetScrollPosition?: number;
            debugNamespace?: string;
            isBottomSheetOpen?: boolean;
            poiKey?: null | string;
        },
    ) => {
        const googleMapInstance = get(googleMapInstanceAtom);
        const layoutId = get(layoutIdAtom);

        if (!googleMapInstance || !layoutId) {
            return {};
        }
        const clientUrl = get(clientUrlAtom);
        const location = getMapLatLngLiteral(googleMapInstance.getCenter());
        const zoom = googleMapInstance.getZoom();

        const overlayHistoryMapState = get(overlayHistoryCurrentMapStateAtom);
        const overlayHistoryMapStateData = isMapDataHistoryState(overlayHistoryMapState?.data)
            ? overlayHistoryMapState.data.mapData
            : null;

        const activePoiKey = get(mapActivePoiKeyStateAtom);
        const poiKeyUpdated = poiKey === undefined ? activePoiKey : poiKey;

        const mapBoundariesForComponents = get(mapBoundariesForComponentsAtom);

        const {
            bottomSheetScrollPosition: bottomSheetScrollPositionHistory,
            isBottomSheetOpen: isBottomSheetOpenHistory,
        } = overlayHistoryMapStateData ?? {};

        logMap('saveMapStateToHistoryState', {
            bottomSheetScrollPosition,
            bottomSheetScrollPositionHistory,
            clientUrl,
            debugNamespace,
            isBottomSheetOpen,
            isBottomSheetOpenHistory,
            location,
            mapBoundariesForComponents,
            poiKey,
            poiKeyUpdated,
            zoom,
        });

        return {
            mapData: {
                activePoiKey: poiKeyUpdated,
                bottomSheetScrollPosition: bottomSheetScrollPosition ?? bottomSheetScrollPositionHistory ?? 0,
                isBottomSheetOpen: isBottomSheetOpen ?? isBottomSheetOpenHistory ?? false,
                location,
                mapBoundariesForComponents,
                url: clientUrl,
                zoom,
            },
        };
    },
);

export const isMapDataHistoryState = (state: unknown): state is MapDataHistoryState => {
    return state !== null && typeof state === 'object' && 'mapData' in state;
};

export default function useMapOverlayHistoryState() {
    const layoutId = useAtomValue(layoutIdAtom);
    const isMapSearchLayout = layoutId?.includes('Map_Search');

    const [clientUrl] = useClientUrl();
    const overlayHistoryState = useOverlayHistoryState<Partial<MapDataHistoryState>>(
        getMapOverlayKey(clientUrl, { layoutId }),
    );
    const overlayHistoryMapStateData = isMapDataHistoryState(overlayHistoryState?.data)
        ? overlayHistoryState.data.mapData
        : null;
    const setMapDataV5EntryTarget = useSetMapDataV5EntryTarget();
    const updateMapOverlayHistoryStateRecord = useSetAtom(updateMapOverlayHistoryStateRecordAtom);

    const openOverlay = React.useCallback(
        (options?: { target?: string; trackingElement?: MapTrackingElement }) => {
            setMapDataV5EntryTarget(options?.target ?? null);

            if (!isMapSearchLayout) {
                overlayHistoryState.openOverlay();
                if (options?.trackingElement) {
                    trackMapOpenClick(options.trackingElement);
                }
            }
        },
        [isMapSearchLayout, overlayHistoryState, setMapDataV5EntryTarget],
    );

    const closeOverlay = React.useCallback(
        (options?: { linkUrl?: string }) => {
            const overlayKey = options?.linkUrl ? getMapOverlayKey(options.linkUrl, { layoutId }) : undefined;
            overlayHistoryState.closeOverlay({ overlayKey });
        },
        [layoutId, overlayHistoryState],
    );

    const saveMapStateToHistoryState = React.useCallback(
        ({
            bottomSheetScrollPosition,
            debugNamespace,
            isBottomSheetOpen,
            poiKey,
        }: {
            bottomSheetScrollPosition?: number;
            debugNamespace?: string;
            isBottomSheetOpen?: boolean;
            poiKey?: null | string;
        } = {}) => {
            // use an atom setter here to access atom values at the time of the timeout running
            const state = updateMapOverlayHistoryStateRecord({
                bottomSheetScrollPosition,
                debugNamespace,
                isBottomSheetOpen,
                poiKey,
            });

            overlayHistoryState.updateOverlayData(state);
        },
        [overlayHistoryState, updateMapOverlayHistoryStateRecord],
    );

    return {
        closeOverlay: isMapSearchLayout ? () => {} : closeOverlay,
        isOverlayOpen: overlayHistoryState.isOverlayOpen,
        openOverlay,
        overlayHistoryMapStateData,
        saveMapStateToHistoryState,
    };
}

export const getMapOverlayKey = (url: string, { layoutId }: { layoutId?: ILayoutId | null } = {}) => {
    if (!isMapSearchLayout(layoutId)) {
        // legacy layout does not match url exactly
        return `map-${getUrlPath(url)}`;
    }
    return `map-${url}`;
};
