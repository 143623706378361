import React from 'react';

export default function useClientOnlyRender() {
    const [shouldRender, setShouldRender] = React.useState(false);

    React.useEffect(() => {
        setShouldRender(true);
    }, []);

    return shouldRender;
}
