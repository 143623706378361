import React from 'react';

import debounce from 'lodash.debounce';

import { useRouterEvents } from '@/core/features/router/router-events';

export default function useHasInteractedRef(contentElement: HTMLElement | Window | null) {
    const hasInteractedRef = React.useRef(false);
    const routerEvents = useRouterEvents();

    React.useEffect(() => {
        if (!contentElement) {
            return;
        }
        const onRouteChangeStart = () => {
            hasInteractedRef.current = false;
        };
        const touchHandler = debounce(() => {
            if (hasInteractedRef.current) {
                return;
            }
            hasInteractedRef.current = true;
        }, 20);

        contentElement.addEventListener('click', touchHandler);
        contentElement.addEventListener('touchstart', touchHandler);
        contentElement.addEventListener('wheel', touchHandler);
        routerEvents.on('routeChangeStart', onRouteChangeStart);

        return () => {
            contentElement.removeEventListener('click', touchHandler);
            contentElement.removeEventListener('wheel', touchHandler);
            contentElement.removeEventListener('scroll', touchHandler);
            routerEvents.off('routeChangeStart', onRouteChangeStart);
        };
    }, [contentElement, routerEvents]);

    return hasInteractedRef;
}
