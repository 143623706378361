import { dataLayerPush } from '@/core/features/tracking/google-data-layer';

export type MapTrackingElement = 'map_filterbar' | 'map_preview' | 'map_textlink';

export function trackMapOpenClick(trackingElement: MapTrackingElement) {
    dataLayerPush({
        element: trackingElement,
        event: 'click',
    });
}
