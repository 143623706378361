import React from 'react';

import { useMetaDataState } from '@/core/features/a-dynamic-page/hooks/meta-data-state';
import { atom, useAtomValue, useSetAtom } from '@/core/features/store/atom-store';

const autoLoadMoreAtom = atom<boolean>(true);

export const useAutoLoadMoreSetting = () => useAtomValue(autoLoadMoreAtom);

export const useHydrateAutoLoadMoreSetting = () => {
    const setAutoLoadMoreSetting = useSetAtom(autoLoadMoreAtom);

    const meta = useMetaDataState();
    const settings = meta?.settings;

    // update on change
    React.useEffect(() => {
        setAutoLoadMoreSetting(settings?.autoLoadMoreMapAccommodations === false ? false : true);
    }, [settings, setAutoLoadMoreSetting]);
};
