import type { GoogleMapBounds, GoogleMapLatLng } from '@/features/map/google-map/google-map-types';
import type { IMapLatLngLiteral, IMapPin, IMapPinsRecord } from '@/features/map/map-data-v5/map-types';

export type BoundariesType = {
    fromLatitude: string;
    fromLongitude: string;
    toLatitude: string;
    toLongitude: string;
    zoom: string;
};

export type FlatMultiPinsRecord = Record<string, IMapPin & { multiPinKey: string }>;

export const getBoundariesObject = (boundaries: GoogleMapBounds, zoom: number): BoundariesType => {
    return {
        fromLatitude: boundaries.getSouthWest().lat().toString(),
        fromLongitude: boundaries.getSouthWest().lng().toString(),
        toLatitude: boundaries.getNorthEast().lat().toString(),
        toLongitude: boundaries.getNorthEast().lng().toString(),
        zoom: zoom.toString(),
    };
};

export const getMapLatLngLiteral = (center?: GoogleMapLatLng): IMapLatLngLiteral => {
    return {
        latitude: parseFloat((center?.lat() ?? 0).toFixed(10)),
        longitude: parseFloat((center?.lng() ?? 0).toFixed(10)),
    };
};

export const getMultiRecordFromPins = (pins: IMapPinsRecord): FlatMultiPinsRecord => {
    return Object.entries(pins).reduce((previousPins, [nextPinKey, nextPinValue]) => {
        if (nextPinValue.markerType !== 'multi') {
            return previousPins;
        }
        const multiPins = Object.entries(nextPinValue.pins).reduce(
            (previousMultiPins, [nextMultiPinKey, nextMultiPinValue]) => ({
                ...previousMultiPins,
                [nextMultiPinKey]: { ...nextMultiPinValue, multiPinKey: nextPinKey },
            }),
            {},
        );
        return { ...previousPins, ...multiPins };
    }, {});
};
